* {
	box-sizing: border-box;
	/* outline: 1px solid red; */
	padding: 0;
	margin: 0;
	font-optical-sizing: auto;
}

body {
	font-family: 'Inter', sans-serif;
	letter-spacing: -0.01em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body,
#root {
	height: 100vh;
	overflow: hidden;
}

.App {
	display: flex;
	height: 100%;
	overflow-y: scroll;
	gap: 0px;
}

.left--sidebar {
	padding: 32px 16px 32px 32px;
	width: 448px;
	height: 100%;
	display: flex;
	align-items: stretch;
	flex-direction: column;
	gap: 32px;
}

.left--sidebar * {
	outline: 0px !important;
}

.left--sidebar--main {
	background: #F8F8F8;
	flex: 1;
	border-radius: 16px;
	padding: 24px 32px 32px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
}


.personal--heading {
	display: flex;
	gap: 20px;
	align-items: center;
	margin-bottom: 16px;
}

.personal--profile-picture {
	width: 64px;
	height: 64px;
	border-radius: 16px;
	overflow: hidden;
}

.personal--profile-picture img {
	width: 64px;
	height: 64px;
}

.personal--subtitles h1 {
	font-size: 18px;
	font-weight: 600;
	padding-bottom: 2px;
}

.personal--subtitles h2 {
	font-size: 13px;
	font-weight: 400;
	color: #888888;
	letter-spacing: -0.02em;
}

.personal--summary {
	margin-bottom: 24px;
}

.personal--summary p {
	font-size: 14px;
	font-weight: 350;
	line-height: 150%;
	color: #000000;
	letter-spacing: -0.01em;
}

.personal--contact-buttons {
	margin-bottom: 40px;
	display: flex;
	gap: 8px;
}

.personal--contact-buttons a {
	flex: 1;
	padding: 10px 4px;
	font-size: 13px;
	font-weight: 450;
	color: #ffffff;
	text-decoration: none;
	border-radius: 8px;
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	border: solid 1px transparent;
	text-shadow: 0px 1px 1px rgba(17, 17, 17, 0.15);
	transition: 0.3s ease;
}

.personal--contact-buttons a img {
	width: 16px;
	height: 16px;
}

.personal--contact-buttons a.button--email {
	background-color: #2AB6FB;
	border-color: #12A0E5;
}

.personal--contact-buttons a.button--email:hover {
	background-color: #25B2F6;
	font-weight: 550;
}

.personal--contact-buttons a.button--linkedin {
	background-color: #2D64BC;
	border-color: #1D51A7;
}

.personal--contact-buttons a.button--linkedin:hover {
	background-color: #2B61B7;
	font-weight: 550;
}

.personal--contact-buttons a.button--telegram {
	background-color: #4799F4;
	border-color: #227BDD;
}

.personal--contact-buttons a.button--telegram:hover {
	background-color: #4495EE;
	font-weight: 550;
}


.latest-expirience--heading {
	display: flex;
	justify-content: space-between;
	padding-bottom: 16px;
}

.latest-expirience--heading h4 {
	font-size: 14px;
	font-weight: 400;
	color: #000000;
}

.latest-expirience--read-more a {
	text-decoration: none;
	font-size: 13px;
	color: #b2b2b2;
	display: flex;
	gap: 4px;
	transition: 0.2s ease;
}

.latest-expirience--read-more img {
	width: 16px;
	height: 16px;
	opacity: 0.3;
	transition: 0.3s ease;
}
.latest-expirience--read-more:hover a {
	color: #000000;
	font-weight: 500;
}

.latest-expirience--read-more:hover img {
	opacity: 1;
}

.latest-expirience--body {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.latest-expirience--item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.latest-expirience--item--content-left {
	display: flex;
	gap: 10px;
	align-items: center;
}

.latest-expirience--item--logo {
	width: 20px;
	height: 20px;
}

.latest-expirience--item--title {
	display: flex;
	flex-direction: column;
	gap: 1px;
}

.latest-expirience--item--title h5 {
	font-size: 14px;
	font-weight: 450;
	letter-spacing: -0.01em;
}

.latest-expirience--item--title h6 {
	font-size: 12px;
	font-weight: 400;
	color: #b2b2b2;
	line-height: 150%;
	letter-spacing: -0.01em;
}

.latest-expirience--item--content-right p {
	font-size: 13px;
	font-weight: 400;
	color: #888888;
	letter-spacing: -0.01em;
}

.left-sidebar--location {
	display: flex;
	gap: 8px;
}

.left-sidebar--location span {
	font-size: 14px;
	font-weight: 400;
	color: #000000;
}

.left-sidebar--location img {
	width: 18px;
	height: 18px;
}

.left--sidebar--footer {
	background-color: #000000;
	border-radius: 16px;
	width: 100%;
	padding: 24px 32px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 14px 8px;
}

.left--sidebar--footer .social--item {
	width: calc(50% - 4px);
}

.social-item--nested {
	width: fit-content;
}

.social-item--nested span {
	padding-left: 4px;
}

.left--sidebar--footer a {
	font-size: 14px;
	font-weight: 400;
	color: #ffffff;
	text-decoration: none;
	letter-spacing: -0.01em;
}

.left--sidebar--footer .social--item a {
	display: flex;
	align-items: center;
	gap: 4px;
}

.left--sidebar--footer .social--icon {
	width: 16px;
	height: 16px;
}

.left--sidebar--footer .icon--button {
	width: 16px;
	height: 16px;
}

.left--sidebar--footer .icon--button--arrow {
	width: 16px;
	height: 16px;
	opacity: 0.4;
	transition: 0.3s ease;
}

.social-item--nested:hover .icon--button--arrow {
	opacity: 1;
}


.content {
	flex: 1;
	height: 100%;
	overflow-y: scroll;
	padding: 32px 32px 0 16px;
}

.container {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	gap: 24px;
	padding-bottom: 40px;
}

.item-large,
.item-small {
	display: flex;
	flex-direction: column;
	gap: 12px;
	text-decoration: none;
}

.item-large {
	width: 100%;
}

.item-small {
	width: calc(50% - 16px);
}

.item-large--preview {
	background-color: #F8F8F8;
	outline: 1px solid #EDEDED;
	border-radius: 16px;
	aspect-ratio: 2;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.item-large--preview-video {
	width: 100%;
	transition: 0.6s ease;
	margin: 0 auto;
}

.item-large--preview:hover .item-large--preview-video {
	width: 103%;
} 

.item-small--preview {
	background-color: #F8F8F8;
	outline: 1px solid #EDEDED;
	border-radius: 16px;
	aspect-ratio: 4 / 3;
	background-size: 100% 100%;
	background-position: center center;
	transition: 0.3s ease;
}

.item-small:hover .item-small--preview {
	background-size: 105% 105%;
}

.item-subtitle {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.item-subtitle--heading {
	display: flex;
	flex-direction: column;
	gap: 2px;
}

.item-subtitle--heading p {
	font-size: 14px;
	font-weight: 400;
	color: #b2b2b2;
	transition: 0.3s ease;
}

.item-large:hover .item-subtitle--heading p,
.item-small:hover .item-subtitle--heading p {
	font-weight: 500;
	color: #888888;
}

.item-subtitle--heading h6 {
	font-size: 14px;
	font-weight: 500;
	color: #000000;
	transition: 0.3s ease;
}

.item-large:hover .item-subtitle--heading h6,
.item-small:hover .item-subtitle--heading h6 {
	font-weight: 600;
}

.item-subtitle--read {
	font-size: 13px;
	font-weight: 400;
	color: #888888;
	text-decoration: none;
	display: flex;
	align-items: center;
	gap: 4px;
	transition: 0.3s ease;
}

.item-large:hover .item-subtitle--read {
	color: #000000;
	font-weight: 500;
}

.item-subtitle--icon {
	transition: 0.3s ease;
	filter: brightness(0) saturate(100%);
	opacity: .4;
	width: 20px;
	height: 20px;
}

.item-large:hover img,
.item-small:hover img {
	opacity: 1;
}

.item-subtitle--icon span {
	transition: 0.3s ease;
}

.item-small:hover a.item-subtitle--icon span {
	opacity: 0;
}

a.item-subtitle--icon img {
	width: 20px;
	height: 20px;
}


@media only screen and (max-width: 1180px) {

	body,
	#root {
		height: fit-content;
		overflow: initial;
	}

	.App {
		display: flex;
		height: 100%;
		overflow: initial;
		gap: 0px;
		flex-direction: column;
	}

	.left--sidebar {
		width: 100%;
		padding: 32px 32px 0;
		height: auto;
		gap: 16px;
	}

	.content {
		flex: 1;
		height: auto;
		overflow-y: scroll;
		padding: 32px 32px 0 32px;
	}

	.left--sidebar--main {
		justify-content: start;
		gap: 32px;
		padding: 24px 32px 32px;
	}

	.personal--summary {
		margin-bottom: 20px;
	}

	.personal--contact-buttons {
		margin-bottom: 32px;
	}

	.latest-expirience--body {
		display: flex;
		flex-wrap: wrap;
		gap: 12px 64px;
		flex-direction: row;
	}

	.latest-expirience--item {
		flex-basis: calc(50% - 32px);
	}

	.left--sidebar--footer {
		gap: 16px 8px;
	}

	.left--sidebar--footer .social--item {
		width: calc(33% - 4px);
	}
}

@media only screen and (max-width: 1080px) {

	.left--sidebar {
		padding: 24px 24px 0;
	}

	.left--sidebar--main {
		padding: 16px 24px 24px;
	}

	.content {
		padding: 24px 24px 0 24px;
	}

	.container {
		gap: 20px;
		padding-bottom: 32px;
	}

	.item-subtitle--icon span {
		display: none;
	}

}

@media only screen and (max-width: 940px) {

	.item-small {
		width: calc(50% - 16px);
	}

	.item-large--1 {
		order: 4;
	}

	.item-small--1 {
		order: 2;
	}

	.item-small--2 {
		order: 3;
	}

	.item-large--2 {
		order: 1;
	}

	.item-small--3 {
		order: 5;
	}

	.item-small--4 {
		order: 6;
	}

	.item-large--3 {
		order: 7;
	}

	.item-small--5 {
		order: 8;
	}

	.item-small--6 {
		order: 9;
	}
}

@media only screen and (max-width: 940px) {

	.latest-expirience--item {
		flex-basis: 100%;
	}

}

@media only screen and (max-width: 532px) {

	.left--sidebar--footer .social--item {
		width: calc(50% - 4px);
	}

	.left--sidebar--main {
		gap: 20px
	}
	.item-small {
		width: calc(50% - 16px);
	}

}

@media only screen and (max-width: 480px) {

	.left--sidebar {
		padding: 20px 16px 0;
	}

	.left--sidebar--main {
		padding: 16px 20px 20px;
	}

	.content {
		padding: 20px 16px 0;
	}

	.container {
		gap: 20px;
		padding-bottom: 24px;
	}

	.item-subtitle--icon span {
		display: none;
	}

}

@media only screen and (min-width: 1180px) and (max-height: 795px) {

	.latest-expirience--item:nth-last-child(1) {
		display: none;
	}
}

@media only screen and (min-width: 1180px)  and (max-height: 745px) {

	.latest-expirience--item:nth-last-child(2) {
		display: none;
	}
}

@media only screen and (min-width: 1180px)  and (max-height: 695px) {

	.latest-expirience--item:nth-last-child(3) {
		display: none;
	}
}